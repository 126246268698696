import { createRef, useEffect, useState } from 'react'
import { GET_EVENT_ID, GET_FULL_EVENT } from '../query'
import ApolloClient from 'apollo-boost'
import useInterval from './useInterval'
import { clog, getCharacterSrc, setPlayerHelper } from '../util/util'
import { useLocation } from 'react-router-dom'

const INTERVAL = 60000 // 10 seconds // TODO

require('dotenv').config()
// TODO -- display toasts or error on form

export default function (ctx) {
  const location = useLocation()
  const [client, setClient] = useState(null)

  const [loading, setLoading] = useState(true)
  const [params, setParams] = useState({})
  const [event, setEvent] = useState({})
  const [player1, setPlayer1] = useState({})
  const [player2, setPlayer2] = useState({})

  useEffect(() => {
    if (ctx?.apiKey) {
      setClient(
        new ApolloClient({
          uri: 'https://api.start.gg/gql/alpha',
          request: (operation) => {
            operation.setContext({
              headers: {
                authorization: `Bearer ${ctx.apiKey}`,
              },
            })
          },
        }),
      )
    }
  }, [ctx])

  useEffect(() => {
    let urlParams = new URLSearchParams(ctx.url?.split('?')?.[1] || '')
    let type = urlParams.get('type') || 'tournament'
    setParams({
      key: urlParams.get('key') || '',
      player: urlParams.get('player'),
      tournament: urlParams.get('tournament'),
      theme: urlParams.get('theme') || 'dark',
      dance: !!urlParams.get('dance') || false,
      type: type,
      p1Image: urlParams.get('p1Image'),
      p1Score: urlParams.get('p1Score'),
      p1Player: urlParams.get('p1Player'),
      p1Tag: urlParams.get('p1Tag') || '',
      p1Dq: urlParams.get('p1Dq'),
      p2Image: urlParams.get('p2Image'),
      p2Score: urlParams.get('p2Score'),
      p2Player: urlParams.get('p2Player'),
      p2Tag: urlParams.get('p2Tag') || '',
      p2Dq: urlParams.get('p2Dq'),
      eRoundText: urlParams.get('eRoundText'),
      eParticipants: urlParams.get('eParticipants'),
      eName: urlParams.get('eName'),
      eShowImage:
        type === 'tournament' ? !!+urlParams.get('eShowImage') : false,
      eShowName: !!+urlParams.get('eShowName'),
      eShowRoundText: !!+urlParams.get('eShowRoundText'),
      eShowParticipants: !!+urlParams.get('eShowParticipants'),
      pPhotos: !!+urlParams.get('pPhotos'),
      swapPlayers: urlParams.get('swapPlayers') === 'true',
    })
    clog('Set URL param data')
  }, [ctx])

  useEffect(() => {
    if (params.type !== 'tournament') {
      clog('Custom configuration')
      setPlayer1({
        image: params.p1Image,
        score: +(params.p1Score || 0),
        player: params.p1Player,
        tag: params.p1Tag,
        dq: !!params.p1Dq || false,
        lastUpdate: null,
      })
      setPlayer2({
        image: params.p2Image,
        score: +(params.p2Score || 0),
        player: params.p2Player,
        tag: params.p2Tag,
        dq: !!params.p2Dq || false,
        lastUpdate: null,
      })
      setEvent({
        fullRoundText: params.eRoundText,
        participants: params.eParticipants,
        name: params.eName,
        showImage: params.eShowImage,
        showName: params.eShowName,
        showRoundText: params.eShowRoundText,
        showParticipants: params.eShowParticipants,
      })
      setLoading(false)
    } else if (params.tournament) {
      clog('Tournament configuration')
      client
        .query({
          query: GET_EVENT_ID,
          variables: {
            slug: params.tournament,
          },
        })
        .then(
          ({ data }) => {
            clog('Fetched event ID')
            let events = data?.tournament?.events || []
            if (events.length === 0) {
              console.error('No events exist.')
              return
            }
            let eventId = events[0].id
            client
              .query({
                query: GET_FULL_EVENT,
                variables: {
                  slug: params.tournament,
                  player: params.player,
                  eventId: eventId,
                },
              })
              .then(
                ({ data: { tournament } }) => {
                  clog('Fetched main event data')

                  if (tournament === null) {
                    console.error('Tournament data does not exist!')
                  }

                  setEvent({
                    id: tournament?.events?.[0]?.id,
                    image: tournament?.images?.[0]?.url,
                    fullRoundText:
                      tournament?.events?.[0]?.entrants?.nodes?.[0]?.standing
                        ?.player?.sets?.nodes?.[0]?.fullRoundText ||
                      params.eRoundText,
                    name: tournament?.name || params.eName,
                    participants:
                      tournament?.events?.[0]?.numEntrants ||
                      params.eParticipants,
                    showImage: params.eShowImage,
                    showName: params.eShowName,
                    showRoundText: params.eShowRoundText,
                    showParticipants: params.eShowParticipants,
                  })
                  clog('Event state set')
                  let slots =
                    tournament?.events?.[0]?.entrants?.nodes?.[0]?.standing
                      ?.player?.sets?.nodes?.[0]?.slots

                  let entrant1Id = slots?.[0]?.entrant?.id
                  let entrant2Id = slots?.[1]?.entrant?.id
                  // let selections = (slots?.[0]?.entrant?.paginatedSets?.nodes?.filter(e => e.game.selections))?.[0]?.game?.selections || [];
                  let selections = []
                  if (params.player) {
                    if (
                      (params.player || '').toUpperCase() ===
                      (
                        slots?.[0]?.entrant?.participants?.[0]?.player
                          ?.gamerTag || ''
                      ).toUpperCase()
                    ) {
                      selections =
                        slots?.[0]?.entrant?.paginatedSets?.nodes?.[0]?.game3
                          ?.selections ||
                        slots?.[0]?.entrant?.paginatedSets?.nodes?.[0]?.game2
                          ?.selections ||
                        slots?.[0]?.entrant?.paginatedSets?.nodes?.[0]?.game1
                          ?.selections ||
                        []
                    } else if (
                      (params.player || '').toUpperCase() ===
                      (
                        slots?.[1]?.entrant?.participants?.[0]?.player
                          ?.gamerTag || ''
                      ).toUpperCase()
                    ) {
                      selections =
                        slots?.[1]?.entrant?.paginatedSets?.nodes?.[0]?.game3
                          ?.selections ||
                        slots?.[1]?.entrant?.paginatedSets?.nodes?.[0]?.game2
                          ?.selections ||
                        slots?.[1]?.entrant?.paginatedSets?.nodes?.[0]?.game1
                          ?.selections ||
                        []
                    }
                  }

                  let p1Character = ''
                  let p2Character = ''
                  // get entrant1 character
                  for (let selection of selections) {
                    switch (selection.entrant.id) {
                      case entrant1Id:
                        p1Character = getCharacterSrc(selection.selectionValue)
                        break
                      case entrant2Id:
                        p2Character = getCharacterSrc(selection.selectionValue)
                        break
                    }
                  }

                  setPlayer1(
                    setPlayerHelper(
                      tournament?.events?.[0]?.entrants?.nodes?.[0]?.standing
                        ?.player?.sets?.nodes?.[0]?.slots?.[0],
                      {
                        image: p1Character,
                        pPhotos: params.pPhotos,
                        // score: params.p1Score,
                        // player: params.p1Player,
                        // tag: params.p1Tag,
                        // dq: params.p1Dq
                      },
                    ),
                  )
                  clog('Player1 state set')
                  setPlayer2(
                    setPlayerHelper(
                      tournament?.events?.[0]?.entrants?.nodes?.[0]?.standing
                        ?.player?.sets?.nodes?.[0]?.slots?.[1],
                      {
                        image: p2Character,
                        pPhotos: params.pPhotos,
                        // score: params.p2Score,
                        // player: params.p2Player,
                        // tag: params.p2Tag,
                        // dq: params.p2Dq
                      },
                    ),
                  )
                  clog('Player2 state set')
                  setLoading(false)
                  clog('Loading state set')
                },
                (err) => {
                  console.error(
                    `Could not run main tournament event query "GET_FULL_EVENT"`,
                    err,
                  )
                  setLoading(false)
                },
              )
          },
          (err) => {
            console.error(
              `Could not find tournament with query "GET_EVENT_ID"!`,
              err,
            )
            setLoading(false)
          },
        )
    }
  }, [params])

  return {
    loading,
    params,
    event,
    player1,
    player2,
  }
}

function useSmashGG(client, ctx, dependencies = []) {}
