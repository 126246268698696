import characters from "../assets/json/characters.json";

export function clog(str) {
    let now = new Date().toLocaleString().replace(",", "").replace(/:.. /, " ");
}

export function getTag(gamerTag) {
    return gamerTag.substring(0, gamerTag.lastIndexOf(" ") - 1).trim();
}

export function getPlayer(gamerTag) {
    return gamerTag
        .substring(gamerTag.lastIndexOf(" ") + 1, gamerTag.length)
        .trim();
}

export const setPlayerHelper = (slot, custom) => {
    let tag =
        slot?.entrant?.name?.indexOf("|") !== -1
            ? slot?.entrant?.name
                ?.replace(`| ${slot.entrant.participants[0].player.gamerTag}`, "")
                .trim() || ""
            : "";
    return {
        image: custom.pPhotos ? (slot?.entrant?.participants?.[0]?.player?.user?.images?.[0]?.url || custom.image) : custom.image,
        score: +custom.score || +(slot?.standing?.stats?.score?.displayValue || 0),
        player: custom.player || slot?.entrant?.participants?.[0]?.player?.gamerTag,
        tag: tag,
        lastUpdate: null,
        dq: !!custom.dq || false,
    };
};

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getCharacterSrc = (characterId) => {
    characterId = parseInt(characterId);
    return characters.find(({id}) => id === characterId)?.images?.[1]?.url;
};

export const getCharacterName = (characterId) => {
    characterId = parseInt(characterId);
    return characters.find(({id}) => id === characterId)?.name || '';
};

export function escapeRegExp(string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "$&");
}
