import {MenuItem, Select} from '@material-ui/core';
import React from 'react';
import {BootstrapInput} from './BootstrapInput';
import characters from '../assets/json/characters.json';


export default function SelectPlayer({id, label, state, setState}) {
    return <Select
        value={state}
        onChange={({target: {value}}) => {
            setState(value);
        }}
        label={label}
        inputProps={{id}}
        input={<BootstrapInput />}
    >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        {characters.map(({name, images: [, {url}]}) =>
            <MenuItem key={name} value={url}>
                <img src={url} width={25} height={25} style={{verticalAlign: 'bottom', marginRight: '8px'}} alt={name} />
                <span>{name}</span>
            </MenuItem>
        )}
    </Select>;
};
