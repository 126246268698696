import React, {
  createRef,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core'
import styled from 'styled-components'
import Header from '../components/Header'
import SelectPlayer from '../components/SelectPlayer'
import { BootstrapInput } from '../components/BootstrapInput'
import CardTitle from '../components/CardTitle'
import Body from '../components/Body'
import Footer from '../components/Footer'
import text from '../assets/json/text.json'
import StyledCard from '../components/StyledCard'
import useTitle from '../hooks/useTitle'
import io from 'socket.io-client'
import { useAppContext } from '../App'
const cryptojs = require('crypto-js')

/*  TODO - create a custom URL to use for a client so they dont have to copy the browser source.... can it be done without a database?  */
const URL = window.location.href

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const FormControlStyled = styled(FormControl)`
  width: 100%;
`

function encodeQueryData(data) {
  const ret = []
  for (let d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }
  return ret.join('&')
}

const buildUserURL = (apiKey) => {
  const hashId = cryptojs.MD5(apiKey).toString()
  return (
    `${window.location.origin}/#/overlay?` +
    encodeQueryData({
      key: hashId,
    })
  )
}
const buildSocketURL = ({
  swapPlayers,
  type,
  tournament,
  player,
  theme,
  p1Image,
  p1Score,
  p1Player,
  p1Tag,
  p1Dq,
  p2Image,
  p2Score,
  p2Player,
  p2Tag,
  p2Dq,
  eRoundText,
  eParticipants,
  eName,
  eShowImage,
  eShowName,
  eShowRoundText,
  eShowParticipants,
  pPhotos,
}) => {
  return (
    `${window.location.origin}/#/overlay?` +
    encodeQueryData({
      type,
      tournament,
      player,
      theme,
      p1Image,
      p1Score,
      p1Player,
      p1Tag,
      p1Dq,
      p2Image,
      p2Score,
      p2Player,
      p2Tag,
      p2Dq,
      eRoundText,
      eParticipants,
      eName,
      eShowImage: eShowImage ? 1 : 0,
      eShowName: eShowName ? 1 : 0,
      eShowRoundText: eShowRoundText ? 1 : 0,
      eShowParticipants: eShowParticipants ? 1 : 0,
      pPhotos: pPhotos ? 1 : 0,
      swapPlayers,
    })
  )
}

function Build() {
  const socket = io.connect(
    window.location.hostname === 'localhost'
      ? `http://localhost:4000`
      // : `http://smashtube.ca:4000`,
      : `https://smashtube-ws.herokuapp.com/`,
  )
  const [connected, setConnected] = useState(false)
  socket.on('connect', () => {
    setConnected(true)
  })

  useTitle('SmashTube - Overlay')
  const { apiKey } = useAppContext()

  const [data, setData] = useState(
    JSON.parse(window.localStorage.getItem('data') || '{}'),
  )
  const {
    type = 'custom',
    p1Image = 'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png', // TODO ? random character
    p1Score = 0,
    p1Player = '',
    p1Tag = '',
    p1Dq = false, // TODO - remove?
    p2Image = 'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png', // TODO ? random character
    p2Score = 0,
    p2Player = '',
    p2Tag = '',
    p2Dq = false, // TODO - remove?
    tournament = '',
    player = '',
    theme = 'metro',
    url = '',
    eRoundText = '',
    eParticipants = '',
    eName = '',
    eShowImage = false,
    eShowName = false,
    eShowRoundText = false,
    eShowParticipants = false,
    pPhotos = false,
    swapPlayers = false,
  } = data

  const setField = (key, value) => {
    setData({ ...data, [key]: value })
  }

  const setHistory = () => {
    window.localStorage.setItem(
      'data',
      JSON.stringify({
        type: type,
        eRoundText: type === 'custom' ? eRoundText : '',
        eParticipants: type === 'custom' ? eParticipants : '',
        eName: type === 'custom' ? eName : '',
        eShowImage: type === 'custom' ? false : eShowImage,
        eShowName: eShowName,
        eShowRoundText: eShowRoundText,
        eShowParticipants: eShowParticipants,
        p1Image: p1Image,
        p1Score: p1Score,
        p1Player: p1Player,
        p1Tag: p1Tag,
        p1Dq: p1Dq,
        p2Image: p2Image,
        p2Score: p2Score,
        p2Player: p2Player,
        p2Tag: p2Tag,
        p2Dq: p2Dq,
        tournament: tournament,
        player: player,
        theme: theme,
        url: url,
        pPhotos: pPhotos,
        swapPlayers,
      }),
    )
  }

  useEffect(() => {
    if (apiKey?.length && connected) {
      const hashId = cryptojs.MD5(apiKey).toString()
      socket.emit('#register', {
        hashId,
        apiKey,
        url: buildSocketURL({
          tournament,
          player,
          theme,
          type,
          p1Image,
          p1Score,
          p1Player,
          p1Tag,
          p1Dq,
          p2Image,
          p2Score,
          p2Player,
          p2Tag,
          p2Dq,
          eRoundText,
          eParticipants,
          eName,
          eShowImage,
          eShowName,
          eShowRoundText,
          eShowParticipants,
          pPhotos,
          swapPlayers,
        }),
      })
    }
    setField('url', buildUserURL(apiKey))
  }, [connected, apiKey])

  useEffect(() => {
    setHistory()
    setField('url', buildUserURL(apiKey))

    if (apiKey.length && connected) {
      const hashId = cryptojs.MD5(apiKey).toString()
      socket.emit('#build', {
        hashId,
        apiKey,
        url: buildSocketURL({
          tournament,
          player,
          theme,
          type,
          p1Image,
          p1Score,
          p1Player,
          p1Tag,
          p1Dq,
          p2Image,
          p2Score,
          p2Player,
          p2Tag,
          p2Dq,
          eRoundText,
          eParticipants,
          eName,
          eShowImage,
          eShowName,
          eShowRoundText,
          eShowParticipants,
          pPhotos,
          swapPlayers,
        }),
      })
    }
  }, [
    connected,
    tournament,
    player,
    theme,
    type,
    p1Image,
    p1Score,
    p1Player,
    p1Tag,
    p1Dq,
    p2Image,
    p2Score,
    p2Player,
    p2Tag,
    p2Dq,
    eRoundText,
    eParticipants,
    eName,
    eShowImage,
    eShowName,
    eShowRoundText,
    eShowParticipants,
    pPhotos,
    swapPlayers,
  ])

  return (
    <Fragment>
      <Header />
      <Body>
        <Container>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={6}>
              <h1>Overlay (Browser Source)</h1>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <code style={{ fontSize: '8pt' }}>
                Bug Report / Feature Request
                <br />
                Email:{' '}
                <a
                  target="_blank"
                  className={'link'}
                  href={`mailto:${text.email}`}
                >
                  {text.email}
                </a>
              </code>
            </Grid>
          </Grid>
          <div style={{ overflowX: 'hidden' }}>
            {' '}
            {/* Body */}
            {type === 'tournament' ? (
              <Fragment>
                <Grid container spacing={3} style={{ height: '100%' }}>
                  <Grid item xs={12} lg={4}>
                    <StyledCard>
                      <CardContent>
                        <CardTitle>Setup</CardTitle>
                        <FormControlStyled variant={'outlined'}>
                          <Select
                            value={type}
                            onChange={({ target: { value } }) => {
                              setField('type', value)
                            }}
                            label={'Theme'}
                            inputProps={{ id: 'theme' }}
                            input={<BootstrapInput />}
                          >
                            <MenuItem value="tournament">
                              Auto generate data from Tournament
                            </MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                          </Select>
                          <FormHelperText>
                            Dynamically generate browser overlay.
                          </FormHelperText>
                          <FormHelperText>
                            <b>
                              Note: this feature is still in progress, please
                              contact me if you come across issues.
                            </b>
                          </FormHelperText>
                        </FormControlStyled>
                        <br />
                        <br />
                        <Fragment>
                          <FormControlStyled variant={'outlined'}>
                            <TextField
                              id="tournament"
                              label="Tournament"
                              variant="outlined"
                              onChange={({ target: { value } }) =>
                                setField('tournament', value)
                              }
                              value={tournament}
                            />
                            <FormHelperText>
                              The name of the tournament to spectate. The
                              tournament name must match the URL tournament
                              name. See the <b>SEED</b> tab for further
                              information if needed.
                            </FormHelperText>
                          </FormControlStyled>
                          <br />
                          <br />
                          <FormControlStyled variant={'outlined'}>
                            <TextField
                              id="player"
                              label="Player to spectate"
                              variant="outlined"
                              value={player}
                              onChange={({ target: { value } }) =>
                                setField('player', value)
                              }
                            />
                            <FormHelperText>
                              The gamerTag of the participant you would like to
                              spectate.
                            </FormHelperText>
                          </FormControlStyled>
                        </Fragment>
                        <br />
                        <br />
                        <FormControlStyled variant={'outlined'}>
                          <Select
                            value={theme}
                            onChange={({ target: { value } }) => {
                              setField('theme', value)
                            }}
                            label={'Theme'}
                            inputProps={{ id: 'theme' }}
                            input={<BootstrapInput />}
                          >
                            <MenuItem value="coinbox">CoinBox</MenuItem>
                            <MenuItem value="metro">Metro</MenuItem>
                            <MenuItem value="youtoogle">YouToogle</MenuItem>
                            <MenuItem value="vg">VG</MenuItem>
                            <MenuItem value="dark">Dark</MenuItem>
                            <MenuItem value="light">Light</MenuItem>
                          </Select>
                          <FormHelperText>Theme to display.</FormHelperText>
                        </FormControlStyled>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="swapPlayers"
                              checked={swapPlayers}
                              onChange={({ target: { checked } }) =>
                                setField('swapPlayers', checked)
                              }
                            />
                          }
                          label="Swap player positions"
                        />
                      </CardContent>
                    </StyledCard>
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <StyledCard>
                      <CardContent>
                        <CardTitle>Options</CardTitle>
                        <Grid container>
                          <Grid item xs={6} md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="eShowImage"
                                  checked={eShowImage}
                                  onChange={({ target: { checked } }) => {
                                    setField('eShowImage', checked)
                                  }}
                                />
                              }
                              label="Show event image"
                            />
                            {/* TODO - show start.gg player image. no will default to character image. */}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="pPhotos"
                                  checked={pPhotos}
                                  onChange={({ target: { checked } }) => {
                                    setField('pPhotos', checked)
                                  }}
                                />
                              }
                              label="Use profile photos"
                            />

                            {/* TODO */}
                            {/* <FormControlLabel
                                                        control={<Checkbox name="eShowParticipants" checked={eShowParticipants} onChange={({target: {checked}}) =>
                                                            setField('eShowParticipants', checked)} />}
                                                        label="Show participant count"
                                                    /> */}
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="showEName"
                                  checked={eShowName}
                                  onChange={({ target: { checked } }) => {
                                    setField('eShowName', checked)
                                  }}
                                />
                              }
                              label="Show event name"
                            />
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="eShowRoundText"
                                  checked={eShowRoundText}
                                  onChange={({ target: { checked } }) =>
                                    setField('eShowRoundText', checked)
                                  }
                                />
                              }
                              label="Show round text"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid container spacing={3} style={{ height: '100%' }}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} lg={3}>
                        <StyledCard>
                          <CardContent>
                            <CardTitle>Setup</CardTitle>
                            <FormControlStyled variant={'outlined'}>
                              <Select
                                value={type}
                                onChange={({ target: { value } }) => {
                                  setField('type', value)
                                }}
                                label={'Theme'}
                                inputProps={{ id: 'theme' }}
                                input={<BootstrapInput />}
                              >
                                <MenuItem value="tournament">
                                  Auto generate data from Tournament
                                </MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                              </Select>
                              {/* <FormHelperText>This overlay mode does not require a start.gg API key <EmojiEmotions style={{fontSize: 'small'}} /></FormHelperText> */}
                            </FormControlStyled>
                            <br />
                            <br />

                            <FormControlStyled variant={'outlined'}>
                              <Select
                                value={theme}
                                onChange={({ target: { value } }) => {
                                  setField('theme', value)
                                }}
                                label={'Theme'}
                                inputProps={{ id: 'theme' }}
                                input={<BootstrapInput />}
                              >
                                <MenuItem value="coinbox">CoinBox</MenuItem>
                                <MenuItem value="metro">Metro</MenuItem>
                                <MenuItem value="youtoogle">YouToogle</MenuItem>
                                <MenuItem value="vg">VG</MenuItem>
                                <MenuItem value="dark">Dark</MenuItem>
                                <MenuItem value="light">Light</MenuItem>
                              </Select>
                              <FormHelperText>Theme to display.</FormHelperText>
                            </FormControlStyled>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="swapPlayers"
                                  checked={swapPlayers}
                                  onChange={({ target: { checked } }) =>
                                    setField('swapPlayers', checked)
                                  }
                                />
                              }
                              label="Swap player positions"
                            />
                          </CardContent>
                        </StyledCard>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <StyledCard>
                          <CardContent>
                            <CardTitle>Event Information</CardTitle>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eShowName}
                                  onChange={({ target: { checked } }) => {
                                    setField('eShowName', checked)
                                  }}
                                  name="showEName"
                                />
                              }
                              label="Show event name"
                            />
                            <br />
                            {eShowName && (
                              <Fragment>
                                <FormControlStyled variant={'outlined'}>
                                  <TextField
                                    id="eName"
                                    label="Name"
                                    variant={'outlined'}
                                    onChange={({ target: { value } }) =>
                                      setField('eName', value)
                                    }
                                    value={eName}
                                  />
                                </FormControlStyled>
                                <br />
                                <br />
                              </Fragment>
                            )}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eShowRoundText}
                                  onChange={({ target: { checked } }) =>
                                    setField('eShowRoundText', checked)
                                  }
                                  name="eShowRoundText"
                                />
                              }
                              label="Show round text"
                            />
                            <br />
                            {eShowRoundText && (
                              <Fragment>
                                <FormControlStyled variant={'outlined'}>
                                  <TextField
                                    id="eRoundText"
                                    label="Name"
                                    variant={'outlined'}
                                    onChange={({ target: { value } }) =>
                                      setField('eRoundText', value)
                                    }
                                    value={eRoundText}
                                  />
                                </FormControlStyled>
                                <br />
                                <br />
                              </Fragment>
                            )}

                            {/* TODO - show participant count somewhere on the overlay  */}
                            {/* <FormControlLabel
                                                        control={<Checkbox checked={eShowParticipants} onChange={({target: {checked}}) => setField('eShowParticipants', checked)} name="eShowParticipants" />}
                                                        label="Show participant count"
                                                    />
                                                    {eShowParticipants && <Fragment>
                                                        <FormControlStyled variant={'outlined'}>
                                                            <TextField id="eParticipants" label="Name" variant={'outlined'} onChange={({target: {value}}) => setField('eParticipants', value)} value={eParticipants} />
                                                        </FormControlStyled><br />
                                                    </Fragment>} */}
                            <br />
                          </CardContent>
                        </StyledCard>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <StyledCard>
                          <CardContent>
                            <CardTitle>Player 1</CardTitle>
                            <Fragment>
                              <FormControlStyled variant={'outlined'}>
                                <TextField
                                  id="p1Player"
                                  label="Name"
                                  variant={'outlined'}
                                  onChange={({ target: { value } }) =>
                                    setField('p1Player', value)
                                  }
                                  value={p1Player}
                                />
                              </FormControlStyled>
                              <br />
                              <br />
                              <FormControlStyled variant={'outlined'}>
                                <TextField
                                  id="p1Tag"
                                  label="Tag Tag (prefix/sponsor)"
                                  variant={'outlined'}
                                  onChange={({ target: { value } }) =>
                                    setField('p1Tag', value)
                                  }
                                  value={p1Tag}
                                />
                              </FormControlStyled>
                              <br />
                              <br />
                              <FormControlStyled variant={'outlined'}>
                                <TextField
                                  id="p1Score"
                                  label="Score"
                                  type="number"
                                  variant={'outlined'}
                                  onChange={({ target: { value } }) =>
                                    setField('p1Score', value)
                                  }
                                  value={p1Score}
                                />
                              </FormControlStyled>
                              <br />
                              <br />
                              <FormControlStyled variant={'outlined'}>
                                <SelectPlayer
                                  id={'p1Image'}
                                  label={'Player 1 - character'}
                                  state={p1Image}
                                  setState={(value) => {
                                    setField('p1Image', value)
                                  }}
                                />
                                {p1Image && (
                                  <Button
                                    onClick={() => setField('p1Image', '')}
                                  >
                                    Unset
                                  </Button>
                                )}
                              </FormControlStyled>
                            </Fragment>
                            <br />
                            <br />
                            <br />
                          </CardContent>
                        </StyledCard>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <StyledCard>
                          <CardContent>
                            <CardTitle>Player 2</CardTitle>
                            <Fragment>
                              <FormControlStyled variant={'outlined'}>
                                <TextField
                                  id="p2Player"
                                  label="Name"
                                  variant={'outlined'}
                                  onChange={({ target: { value } }) =>
                                    setField('p2Player', value)
                                  }
                                  value={p2Player}
                                />
                              </FormControlStyled>
                              <br />
                              <br />
                              <FormControlStyled variant={'outlined'}>
                                <TextField
                                  id="p2Tag"
                                  label="Tag (prefix/sponsor)"
                                  variant={'outlined'}
                                  onChange={({ target: { value } }) =>
                                    setField('p2Tag', value)
                                  }
                                  value={p2Tag}
                                />
                              </FormControlStyled>
                              <br />
                              <br />
                              <FormControlStyled variant={'outlined'}>
                                <TextField
                                  id="p2Score"
                                  label="Score"
                                  type="number"
                                  variant={'outlined'}
                                  onChange={({ target: { value } }) =>
                                    setField('p2Score', value)
                                  }
                                  value={p2Score}
                                />
                              </FormControlStyled>
                              <br />
                              <br />
                              <FormControlStyled variant={'outlined'}>
                                <SelectPlayer
                                  id={'p2Image'}
                                  label={'Player 2 - character'}
                                  state={p2Image}
                                  setState={(value) =>
                                    setField('p2Image', value)
                                  }
                                />
                                {p2Image && (
                                  <Button
                                    onClick={() => setField('p2Image', '')}
                                  >
                                    Unset
                                  </Button>
                                )}
                              </FormControlStyled>
                            </Fragment>
                            <br />
                          </CardContent>
                        </StyledCard>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )}
            <br />
          </div>
          <StyledCard>
            <CardContent>
              <Grid
                container
                alignContent={'center'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item xs={6}>
                  <CardTitle>
                    <span>Browser Source</span>
                  </CardTitle>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color={'primary'}
                    onClick={() => window.open(url)}
                  >
                    Open Preview
                  </Button>
                </Grid>
              </Grid>
              <center>
                <FormControl variant={'outlined'} style={{ width: '100%' }}>
                  <TextField
                    id="player"
                    label="Browser Source"
                    variant="outlined"
                    disabled
                    value={url}
                  />
                  <FormHelperText>
                    This page must remain open for the overlay to be connected
                    to it.
                  </FormHelperText>
                  <FormHelperText>
                    Copy and paste this URL as your browser source
                  </FormHelperText>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      navigator.clipboard.writeText(url)
                    }}
                  >
                    Copy to clipboard
                  </Button>
                </FormControl>
              </center>
            </CardContent>
          </StyledCard>

          <br />

          {/* TODO: should probably redo this */}
          {/* <StyledCard>
                    <CardContent>
                        <CardTitle>Browser Source Preview</CardTitle>
                        <center style={{background: 'url("https://i.gifer.com/fxqW.gif")', backgroundSize: 'cover', backgroundPosition: '0px 450px'}}>
                            <iframe src={url} className={'browser-preview'} />
                        </center>
                    </CardContent>
                </StyledCard> */}
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Body>
      <Footer />
    </Fragment>
  )
}

export default Build

// http://localhost:3002/#/overlay?key=d95525f1c6ed63ddf12be8d10d741233
// http://localhost:3002/#/overlay?key=d95525f1c6ed63ddf12be8d10d741233
