import React, { useContext } from 'react'
import { Context } from '../route/Overlay'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import loadingGif from '../assets/img/loading.gif'
import eventImage from '../assets/img/event.png'
import SsbuIcon2 from '../assets/img/logo.gif'

export default function CoinBox() {
  const { loading, player1, player2, event, params } = useContext(Context)

  return (
    <center
      style={{
        width: '100%',
        paddingTop: '5px',
        backgroundColor: 'transparent !important',
      }}
    >
      {event.showImage && (
        <>
          {loading || params.dance ? (
            <EventImage src={loadingGif} />
          ) : (
            <EventImage src={event.image || eventImage} />
          )}
        </>
      )}

      {(event.showName || event.showRoundText) && (
        <EventInfoGrid container alignItems="center" orientation={'left'}>
          {event.showName && (
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                color: 'white',
                textShadow: '1px 1px solid black',
                fontWeight: 'bold',
              }}
            >
              <TextFamily>{(event.name || '').toUpperCase()}</TextFamily>
            </Grid>
          )}
          {event.showRoundText && (
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                color: 'white',
                textShadow: '1px 1px solid black',
                fontStyle: 'italic',
              }}
            >
              <TextFamily>
                {(event.fullRoundText || '').toUpperCase()}
              </TextFamily>
            </Grid>
          )}
        </EventInfoGrid>
      )}

      <Grid
        container
        style={{
          backgroundColor: 'transparent',
          minWidth: '1080px',
          maxWidth: '1080px',
        }}
        alignItems={'center'}
      >
        <Grid item xs={1} />
        <PlayerGridContainer item xs={4}>
          <PlayerGrid container alignItems="center" orientation={'left'}>
            <Grid item xs={2} style={{ textAlign: 'left' }}>
              {loading || params.dance ? (
                <Image
                  src={loadingGif}
                  style={{ transform: 'scaleX(-1)' }}
                  width={35}
                  height={35}
                />
              ) : (
                <Image
                  src={
                    player1.image ||
                    'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                  }
                  width={35}
                  height={35}
                />
              )}
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player1.tag && (
                <TextAlt>{(player1.tag || '').toUpperCase()} | </TextAlt>
              )}
              <Text>{(player1.player || '').toUpperCase()}</Text>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'center', backgroundColor: '#4487e8' }}
            >
              <TextScore>{player1.score || 0}</TextScore>
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>
        <Grid item xs={2}>
          {/* Event image */}
          <EventImageBackground style={{ width: '100%' }}>
            {event.showImage ? (
              <>
                {loading || params.dance ? (
                  <EventImage src={SsbuIcon2} />
                ) : (
                  <EventImage src={event.image || eventImage} />
                )}
              </>
            ) : (
              <EventImage
                style={{ marginTop: '4px' }}
                src={'https://i.ibb.co/BLzkB5Z/coinbox2.png'}
              />
            )}
          </EventImageBackground>
        </Grid>
        <PlayerGridContainer item xs={4}>
          <PlayerGrid container alignItems="center" orientation={'right'}>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'center', backgroundColor: '#4487e8' }}
            >
              <TextScore>{player2.score || 0}</TextScore>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player2.tag && (
                <TextAlt>{(player2.tag || '').toUpperCase()} | </TextAlt>
              )}
              <Text>{(player2.player || '').toUpperCase()}</Text>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              {loading || params.dance ? (
                <Image src={loadingGif} width={35} height={35} />
              ) : (
                <Image
                  src={
                    player2.image ||
                    'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                  }
                  width={35}
                  height={35}
                />
              )}
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>
        <Grid item xs={1} />
      </Grid>
    </center>
  )
}

const TextFamily = styled.span`
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`

const Body = styled.div`
  width: 100%;
  padding-top: 5px;
  background-color: transparent !important;
`
const Text = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px #000;
  font-size: x-large;
  vertical-align: middle;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const TextScore = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px black;
  font-size: xx-large;
  vertical-align: middle;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const TextAlt = styled.span`
  user-select: none;
  color: #888888;
  text-shadow: 1px 1px #000;
  font-size: large;
  vertical-align: middle;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`

const EventName = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px #000;
  font-size: large;
  font-weight: bold;
  vertical-align: middle;
  padding: 2px;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const FullRoundText = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px #000;
  font-size: medium;
  font-weight: bold;
  vertical-align: middle;
  padding: 2px;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const Image = styled.img`
  padding: 1px;
  display: inline-block;
  vertical-align: middle;
  max-width: 35px;
  height: 35px;
  width: auto;
`

const EventImage = styled.img`
  width: 40px;
  height: 40px;
#  border-radius: 40px;
  vertical-align: middle;
  mix-blend-mode: screen;
 # background-color: black;
`

const EventImageBackground = styled(Grid)`
  display: inline-block;
  #padding-top: 2px;
  width: 50px;
  height: 50px;
  #border-radius: 4px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`
const PlayerGridContainer = styled(Grid)`
  padding: 2px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`

const PlayerGrid = styled(Grid)`
  #background-color: #112836;
  background-color: transparent;
  border: 4px solid #4487e8;
`

const EventInfoGrid = styled(Grid)`
  #background-color: #112836;
  background-color: transparent;
  border: 4px solid #4487e8;
  position: absolute;
  width: 300px;
  top: 30px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`
