import {Container} from '@material-ui/core';
import React from 'react';
import cn from 'classnames';

export default function Body({children, className, ...props}) {
    return <div className={cn('body', className)} {...props}>
        <Container>
            {children}
        </Container>
    </div>;

}
