import {
    Button,
    Grid,
    Tooltip,
    useMediaQuery,
    IconButton
} from "@material-ui/core";
import React from "react";
import PayPalDonateButton from "./PayPalDonateButton";
import text from "../assets/json/text.json";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import PayPalDonateImage from "./PayPalDonateImage";
import {Reddit, Twitter, YouTube} from "@material-ui/icons";

export default function Footer() {
    let history = useHistory();
    const isBigScreen = useMediaQuery("(min-width:1100px)");

    return (
        <div className={"footer"}>
            <Grid
                container
                spacing={0}
                style={{height: "100%"}}
                alignItems={"center"}
            >
                <Grid item xs={12} md={12}>
                    <div className={"small"}>
                        Feedback / Need To Contact Us? &nbsp;&nbsp;&nbsp;
                        <b>
                            <a
                                target="_blank"
                                className={"link small"}
                                href={`mailto:${text.email}`}
                            >
                                {text.email}
                            </a>
                        </b>
                    </div>

                    <div>
                        <a
                            className={"link small"}
                            onClick={() => history.push("/privacyPolicy")}
                        >
                            Privacy Policy
                        </a>
                        &nbsp;&nbsp;
                        <a
                            className={"link small"}
                            onClick={() => history.push("/termsAndConditions")}
                        >
                            Terms and Conditions
                        </a>
                        &nbsp;&nbsp;
                        <a
                            className={"link small"}
                            onClick={() => history.push("/changelog")}
                        >
                            Changelog
                        </a>
                    </div>
                    <Grid
                        spacing={0}
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <Tooltip
                                title={
                                    <span>
                    Support <b>YouToogle</b>!<br/>
                    Give them a like or follow if you enjoyed the animated
                    overlays :D
                  </span>
                                }
                                placement="bottom"
                            >
                                <IconButton
                                    onClick={() =>
                                        window.open("https://www.youtube.com/c/YouToogle")
                                    }
                                >
                                    <YouTube
                                        style={{
                                            verticalAlign: "middle",
                                            fontSize: "16px",
                                            color: "rgb(255, 0,0)"
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="@SSmashtube" placement="bottom">
                                <IconButton
                                    onClick={() => window.open("https://twitter.com/SSmashtube")}
                                >
                                    <Twitter
                                        style={{
                                            verticalAlign: "middle",
                                            fontSize: "16px",
                                            color: "rgb(29, 155, 240)"
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="r/SmashTubeSSBU" placement="bottom">
                                <IconButton
                                    onClick={() =>
                                        window.open("https://www.reddit.com/r/SmashTubeSSBU/")
                                    }
                                >
                                    <Reddit
                                        style={{
                                            verticalAlign: "middle",
                                            fontSize: "16px",
                                            color: "#ff4500"
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title="thematchbox.gg" placement="bottom">
                                <IconButton
                                    onClick={() =>
                                        window.open("https://thematchbox.gg/")
                                    }
                                >
                                    <img alt="https://thematchbox.gg/" style={{height: "16px", width: "16px", verticalAlign: "middle"}} src={"https://storage.googleapis.com/smashpros.gg/matchbox.png"}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {isBigScreen ?
                    <Grid item xs={8}>
                        <Grid container spacing={0}>
                            <Grid item xs={4}>
                                <a className={"no-underline"} href={'https://ko-fi.com/R5R464IT6'} target={'_blank'}>
                                    <Button variant="contained" color={"primary"} style={{height: '52px'}}>
                                        <img
                                            src={"https://storage.ko-fi.com/cdn/cup-border.png"}
                                            width={55}
                                            alt='Buy me a coffee'
                                        />
                                        <b>&nbsp;&nbsp;Buy me a coffee</b>
                                    </Button>
                                </a>
                            </Grid>
                            <Grid item xs={4} >
                                <PayPalDonateButton />
                            </Grid>
                            <Grid item xs={4}   >
                                <a
                                    className={"no-underline"}
                                    href={"https://www.patreon.com/bePatron?u=60786745"}
                                    target={"_blank"}
                                >
                                    <Button variant="contained" color={"primary"} style={{height: '52px'}}>
                                        <img
                                            src={"https://i.ibb.co/QN2X9ck/patreon-small.png"}
                                            width={40}
                                            alt='Patreon image'
                                        />
                                        <b>&nbsp;&nbsp;Become a Patreon</b>
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    : <Grid item xs={8} >
                        <Grid container spacing={0} alignItems="center">
                            <Grid item xs={4}>
                                <Tooltip title="Buy me a coffee" placement="top">
                                    <IconButton onClick={() => window.open('https://ko-fi.com/R5R464IT6')}>
                                        <div style={{width: '35px', height: '35px', verticalAlign: 'middle'}}>
                                            <img
                                                src={"https://storage.ko-fi.com/cdn/cup-border.png"}
                                                alt='Buy me a coffee'
                                                style={{width: '35px', verticalAlign: 'bottom'}}
                                            />
                                        </div>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4} >
                                <PayPalDonateImage />
                            </Grid>
                            <Grid item xs={4} >
                                <Tooltip title="Become a Patreon" placement="top">
                                    <IconButton onClick={() => window.open('https://www.patreon.com/bePatron?u=60786745')}>
                                        <img
                                            src={"https://i.ibb.co/QN2X9ck/patreon-small.png"}
                                            style={{width: '35px', height: '35px', verticalAlign: 'middle'}}
                                            alt='Become a Patreon'
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <em style={{fontWeight: 'lighter', fontSize: 'small', verticalAlign: 'middle'}}>Support us ^_^</em>
                            </Grid>
                        </Grid>
                    </Grid> */}
                {/* <Grid item xs={1} md={1}>
          <Grid
            container
            direction={'column'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
          >
            <Grid item>
              <Tooltip
                title={
                  <span>
                    Support <b>YouToogle</b>!<br />
                    Give them a like or follow if you enjoyed the animated
                    overlays :D
                  </span>
                }
                placement="bottom"
              >
                <IconButton
                  onClick={() =>
                    window.open('https://www.youtube.com/c/YouToogle')
                  }
                >
                  <YouTube
                    style={{
                      verticalAlign: 'middle',
                      fontSize: '16px',
                      color: 'rgb(255, 0,0)',
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="@SSmashtube" placement="bottom">
                <IconButton
                  onClick={() => window.open('https://twitter.com/SSmashtube')}
                >
                  <Twitter
                    style={{
                      verticalAlign: 'middle',
                      fontSize: '16px',
                      color: 'rgb(29, 155, 240)',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="r/SmashTubeSSBU" placement="bottom">
                <IconButton
                  onClick={() =>
                    window.open('https://www.reddit.com/r/SmashTubeSSBU/')
                  }
                >
                  <Reddit
                    style={{
                      verticalAlign: 'middle',
                      fontSize: '16px',
                      color: '#ff4500',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid> */}
            </Grid>
        </div>
    );
}
