import React, {Fragment, createContext, useEffect, useState} from "react";
import Header from "../components/Header";
import {Card, CardContent, Container} from "@material-ui/core";
import Body from "../components/Body";
import Footer from "../components/Footer";
import marked from "marked";
import CardTitle from "../components/CardTitle";
import CardSubTitle from "../components/CardSubTitle";
import useTitle from "../hooks/useTitle";

export default function Changelog() {
    useTitle('SmashTube - Changelog');
    const readmePath = require("../CHANGELOG.md");
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch(readmePath)
            .then(response => response.text())
            .then(text => {
                setMarkdown(marked(text));
            });
    }, []);
    return (
        <Fragment>
            <Header />
            <Body>
                <center><CardSubTitle>Changelog</CardSubTitle></center>
                <article dangerouslySetInnerHTML={{__html: markdown}}></article>
            </Body>
            <Footer />
        </Fragment>
    );
}
