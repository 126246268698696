import React, { Fragment, useState } from 'react'
import Header from '../components/Header'
import {
  Box,
  CardContent,
  Container,
  Grid,
  Snackbar,
  Typography,
  Icon,
  IconButton,
} from '@material-ui/core'
import { EmojiEmotions } from '@material-ui/icons'
import CardTitle from '../components/CardTitle'
import CardSubTitle from '../components/CardSubTitle'
import Body from '../components/Body'
import Footer from '../components/Footer'
import { home } from '../assets/json/text.json'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import text from '../assets/json/text.json'
import StyledCard from '../components/StyledCard'
import useTitle from '../hooks/useTitle'
import { useHistory } from 'react-router-dom'

const SmashGG = ({ s = true }) => (
  <a className={'smashgg'} href="https://start.gg/" target="_blank">
    smash.gg{s ? "'s" : ''}
  </a>
)

const StartGG = ({ s = true }) => (
  <a className={'startgg'} href="https://start.gg/" target="_blank">
    start.gg{s ? "'s" : ''}
  </a>
)

function Alert(props) {
  return null
}

Alert.propTypes = {
  severity: PropTypes.string,
  onClose: PropTypes.any,
  children: PropTypes.node,
}
export default function Home() {
  useTitle('SmashTube - SSBU Streaming and Tournament Utilities')
  let history = useHistory()

  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'left'
  return (
    <Fragment>
      <Header />
      <Body>
        <Container>
          <center>
            <h1>{home.title}</h1>
            <CardSubTitle>{home.subtitle}</CardSubTitle>
          </center>

          {/* style={{backgroundImage: 'url(https://i.ibb.co/WHQQvvc/donkey-kong-gorilla.gif)', backgroundRepeat: 'no-repeat'}} */}
          <StyledCard>
            <CardContent>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                spacing={0}
              >
                <Grid item xs={12} sm={10}>
                  <CardSubTitle>Version 2.2</CardSubTitle>
                  <ul>
                    <li>Cleaned up UI.</li>
                    <li>
                      <a
                        className={'link small'}
                        onClick={() => history.push('/changelog')}
                      >
                        View the changelog
                      </a>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
                  <img
                    src="https://i.ibb.co/WHQQvvc/donkey-kong-gorilla.gif"
                    alt="seeding-Example"
                    border="0"
                    style={{
                      width: '100%',
                      minWidth: '75px',
                      borderRadius: '4px',
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          <br />
          <br />

          <StyledCard>
            <CardContent>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'flex-start'}
                spacing={3}
              >
                <Grid item sm={6}>
                  <CardSubTitle>Find your next tourney!</CardSubTitle>
                  <p>
                    SmashTube version 2.1 added the <b>UPCOMING</b> tab!
                  </p>
                  <p>
                    Quickly find online SSBU tournaments on{' '}
                    <StartGG s={false} />. Tournament start time, registration
                    time and participant count is now easily accessible! No more
                    clicking every tournament on <StartGG s={false} /> for times
                    and timezones.
                  </p>
                  <p>
                    Clicking on any of the listed tournaments will bring you
                    directly to the tournament page on <StartGG s={false} />.
                  </p>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'right' }}>
                  <img
                    src="https://i.ibb.co/4d4gY8Q/upcoming.png"
                    alt="seeding-Example"
                    border="0"
                    style={{ width: '100%', borderRadius: '4px' }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          <br />
          <br />

          <StyledCard>
            <CardContent>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                spacing={3}
              >
                <Grid item sm={6}>
                  <CardSubTitle>Accelerate Seeding</CardSubTitle>
                  <p>
                    Using SmashTube, you can save time on seeding your
                    tournament by simply providing your tournament name within
                    the <b>SEED</b> tab.
                  </p>
                  <p>
                    <em>
                      Currently, seeding can only be done by user win/loss
                      ratio, but other variations on seeding will be added at a
                      later time.
                    </em>
                  </p>
                  <p>
                    The only information needed to utilize <StartGG /> open API
                    is an <b>Authentication Token</b>.
                  </p>
                  <p>
                    An <b>Authentication Token</b> can be obtained by following
                    steps:
                  </p>
                  <ul>
                    <li>Log into your account</li>
                    <li>Click "Profile Settings" (your profile image)</li>
                    <li>
                      At the top center of the page, click the "Profile
                      Settings" dropdown
                    </li>
                    <li>Click "Developer Settings"</li>
                    <li>Click the "Create new token" button</li>
                    <li>
                      Add the token to the top right of this page in the
                      "start.gg API key"
                    </li>
                    <li>
                      Save your <b>Authentication Token</b> somewhere where you
                      wont lose it. You'll need it later
                    </li>
                  </ul>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'right' }}>
                  <img
                    src="https://i.ibb.co/sCs0gGw/seeding-Example.gif"
                    alt="seeding-Example"
                    border="0"
                    style={{ width: '100%', borderRadius: '4px' }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>

          <br />
          <br />

          <StyledCard>
            <CardContent>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'flex-start'}
                spacing={3}
              >
                <Grid item sm={6}>
                  <CardSubTitle>Know Your Enemy</CardSubTitle>
                  <p>
                    Using the <b>STATS</b> tab you are able to get information
                    on all the participants in a given tournament. Retrieve
                    every user's wins, losses, ratio, favorite character and
                    socials. Leave the page open during your sets to get a heads
                    up for whats next to come!
                  </p>
                  <p>
                    In version 2.0 of SmashTube.ca, it is no longer required to
                    provide an <StartGG /> <b>Authentication Token</b>. You may
                    now just instantly obtain tournament statistics.
                  </p>
                </Grid>
                <Grid item sm={6} style={{ textAlign: 'right' }}>
                  {/* <img
                    src="https://i.ibb.co/zSg2pGS/stats.gif"
                    alt="seeding-Example"
                    border="0"
                    style={{width: "100%", borderRadius: "4px"}}
                  /> */}
                  {/* <img
                    src="https://i.ibb.co/Xscn4dm/stats2.png"
                    alt="seeding-Example"
                    border="0"
                    style={{width: "100%", borderRadius: "4px"}}
                  /> */}
                  <img
                    src="https://i.ibb.co/W61hCHj/stat3.png"
                    alt="seeding-Example"
                    border="0"
                    style={{ width: '100%', borderRadius: '4px' }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
          <br />
          <br />
          <StyledCard>
            <CardContent>
              <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                spacing={3}
              >
                <Grid item lg={6}>
                  <CardSubTitle>Making Streaming Easier</CardSubTitle>
                  <p>
                    This site is used by tournament organizers and competitors
                    alike. Within the <b>OVERLAY</b> tab, users can create
                    static and dynamic browser stream overlays tailored for
                    Super Smash Brothers Ultimate.
                  </p>
                  <p>
                    By selecting "custom" setup in the <b>OVERLAY</b> tab, you
                    can have full control of the way your overlay looks. Choose
                    from multiple themes, text options and character icons for
                    your browser overlays.
                  </p>
                  <p>
                    <b>Work in-progress:</b> No longer do you have to update
                    player names and scores after each match or set. Just select
                    "Auto generate data from tournament" setup in the{' '}
                    <b>OVERLAY</b> tab and the player you would like to spectate
                    and copy the browser source leaving you with more time to
                    focus on the game.
                  </p>
                  <p>
                    Compatible with{' '}
                    <a className={'link'} href={'https://twitch.tv'}>
                      Twitch
                    </a>
                    ,{' '}
                    <a className={'link'} href={'https://obsproject.com/'}>
                      OBS
                    </a>{' '}
                    and all other streaming services!
                  </p>
                </Grid>
                <Grid item lg={6} style={{ textAlign: 'right' }}>
                  <iframe
                    src="https://player.twitch.tv/?video=1154344605&parent=www.smashtube.ca&autoplay=true&time=1h19m40s"
                    allowFullScreen={true}
                    style={{
                      width: '560px',
                      height: '315px',
                      border: '3px solid black',
                      borderRadius: '15px',
                    }}
                  ></iframe>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
          <br />
          <br />
          <StyledCard>
            <CardContent>
              <CardSubTitle>Coming Soon!</CardSubTitle>
              <p>
                This site is still a work in progress, but expect many updates
                and changes!
              </p>
              <ul>
                <li>Additional overlay themes and options</li>
                <li>Optimization and bug fixes for stream overlays</li>
                <li>
                  A <b>HELP</b> tab to assist new users
                </li>
              </ul>
              <p>
                Feedback is also welcome <EmojiEmotions />! Please direct any
                feedback to{' '}
                <a
                  target="_blank"
                  className={'link'}
                  href={`mailto:${text.email}`}
                >
                  {text.email}
                </a>
                .
              </p>
            </CardContent>
          </StyledCard>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Body>
      <Footer />
    </Fragment>
  )
}
