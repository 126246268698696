import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
  LinearProgress,
} from '@material-ui/core'
import CardTitle from '../components/CardTitle'
import Body from '../components/Body'
import Footer from '../components/Footer'
import Toast from '../components/Toast'
import text from '../assets/json/text.json'
import StyledCard from '../components/StyledCard'
import useTournamentStats from '../hooks/useTournamentStats'
import { getCharacterName, getCharacterSrc } from '../util/util'
import SearchBar from 'material-ui-search-bar'
import ToastSpinner from '../components/ToastSpinner'
import ApolloClient from 'apollo-boost'
import gql from 'graphql-tag'
import useTitle from '../hooks/useTitle'
import useUpcomingTournaments from '../hooks/useUpcomingTournaments'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useAppContext } from '../App'
import StyledHr from '../components/StyledHr'

const FormControlStyled = styled(FormControl)`
  width: 100%;
`
const StatsLink = styled.span`
  margin-left: 4px;
  padding: 4px;
  font-size: smaller;
  color: #999;
  cursor: pointer;
  background-color: #424242;
  border-radius: 4px;
  &:hover {
    color: white;
  }
`
/*  TODO: maybe add the hover?  */
const StyledTableRow = styled(TableRow)`
    background-color: #424242;
    transition: all 0.2s ease;
    &:hover{
        background-color: #535353;
   //     background: ${({ image }) => `url(${image})` || ''};
    }
`

const StyledTableData = styled.span`
  background-color: #424242;
  padding: 4px;
  border-radius: 4px;
`

const StyledA = styled.a`
  background-color: #424242;
  padding: 2px;
  border-radius: 2px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  color: #f57c00;
  &:hover {
    color: #b85d00;
  }
`

export default function Upcoming() {
  useTitle('SmashTube - Statistics')
  const { setTournament } = useAppContext()
  let history = useHistory()
  const [
    upcomingTournaments,
    { loading, setLoading, message, setMessage },
  ] = useUpcomingTournaments()

  // Screen size
  const useFullSizeDataTable = useMediaQuery('(min-width:550px)')

  /* TABLE THINGS */
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // review -search things
  const [searched, setSearched] = useState('')
  const [rows, setRows] = useState([])
  const cancelSearch = () => {
    setSearched('')
    requestSearch(searched)
  }
  const requestSearch = (searchedVal) => {
    const filteredRows = upcomingTournaments.filter((row) => {
      return (row.name || '').toLowerCase().includes(searchedVal.toLowerCase())
    })
    setPage(0)
    setRows(filteredRows)
  }

  useEffect(() => {
    setRows(upcomingTournaments || [])
  }, [upcomingTournaments])
  return (
    <Fragment>
      <Header />
      <Body className={'body'}>
        <Container>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={6}>
              <h1>Upcoming Online SSBU Tournaments</h1>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <code style={{ fontSize: '8pt' }}>
                Bug Report / Feature Request
                <br />
                Email:{' '}
                <a
                  target="_blank"
                  className={'link'}
                  href={`mailto:${text.email}`}
                >
                  {text.email}
                </a>
              </code>
            </Grid>
          </Grid>

          {/* Seed data */}
          <StyledCard>
            <CardContent>
              {/* <CardTitle>Tournament Participant Results</CardTitle> */}
              {upcomingTournaments?.length ? (
                <>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <br />
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {useFullSizeDataTable ? (
                            <>
                              <TableCell align="left" style={{ width: '30px' }}>
                                {/* PROFILE PHOTO */}
                              </TableCell>
                              <TableCell align="left">
                                <b>Name</b>
                              </TableCell>
                              <TableCell align="center">
                                <b># Attendees</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Starts At</b>
                              </TableCell>
                              <TableCell align="center">
                                <b>Registration Closes At</b>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell
                                align="left"
                                style={{ width: '100px' }}
                              >
                                <b>Name</b>
                              </TableCell>
                              <TableCell align="left">
                                <b>Info</b>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? rows.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                          : rows
                        ).map(
                          (
                            {
                              id,
                              name,
                              numAttendees,
                              links,
                              images,
                              events,
                              url,
                              isOnline,
                              tournamentType,
                              isRegistrationOpen,
                              startAt,
                              endAt,
                              eventRegistrationClosesAt,
                            },
                            idx,
                          ) => (
                            <StyledTableRow key={id} image={images?.[1]?.url}>
                              {/* <TableRow key={id} style={{background: `url(${images?.[1]?.url})`}}> */}
                              {useFullSizeDataTable ? (
                                <>
                                  <TableCell
                                    scope="row"
                                    style={{
                                      width: '30px',
                                      padding: '0 0 0 15px',
                                      margin: '0',
                                    }}
                                  >
                                    {images?.[0]?.url ? (
                                      <img
                                        style={{
                                          width: '45px',
                                          height: '45px',
                                          verticalAlign: 'middle',
                                          borderRadius: '4px',
                                        }}
                                        src={images?.[0].url}
                                      />
                                    ) : (
                                      <img
                                        style={{
                                          width: '45px',
                                          height: '45px',
                                          verticalAlign: 'middle',
                                          borderRadius: '4px',
                                        }}
                                        src={
                                          'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                                        }
                                      />
                                    )}{' '}
                                    {/* RANDOM CHARACTER */}
                                  </TableCell>
                                  <TableCell align="left">
                                    <StyledA
                                      href={`https://start.gg${url}`}
                                      target="_blank"
                                    >
                                      {name}
                                    </StyledA>
                                    <StatsLink
                                      onClick={() => {
                                        setTournament(
                                          url.replace('/tournament/', ''),
                                        )
                                        history.push('/stats')
                                      }}
                                    >
                                      stats
                                    </StatsLink>
                                  </TableCell>
                                  <TableCell align="center">
                                    <StyledTableData>
                                      {numAttendees}
                                    </StyledTableData>
                                  </TableCell>
                                  <TableCell align="center">
                                    <StyledTableData>{startAt}</StyledTableData>
                                  </TableCell>
                                  <TableCell align="center">
                                    <StyledTableData>
                                      {eventRegistrationClosesAt || 'n/a'}
                                    </StyledTableData>
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell
                                    align="left"
                                    style={{ padding: '16px 0 16px 0' }}
                                  >
                                    <div
                                      style={{
                                        position: 'absolute',
                                        width: '100%',
                                        background: `url(${images?.[1]?.url})`,
                                        backgroundSize: '150px',
                                        filter: 'blur(4px)',
                                      }}
                                    />
                                    <center>
                                      <StyledA
                                        href={`https://start.gg${url}`}
                                        target="_blank"
                                      >
                                        {name}
                                      </StyledA>
                                      <br />
                                      {images?.[0]?.url ? (
                                        <img
                                          style={{
                                            width: '100px',
                                            verticalAlign: 'middle',
                                          }}
                                          src={images?.[0].url}
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            width: '100px',
                                            verticalAlign: 'middle',
                                          }}
                                          src={
                                            'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                                          }
                                        />
                                      )}
                                      <br />
                                      <StatsLink
                                        onClick={() => {
                                          setTournament(
                                            url.replace('/tournament/', ''),
                                          )
                                          history.push('/stats')
                                        }}
                                      >
                                        stats
                                      </StatsLink>
                                    </center>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ padding: '0' }}
                                  >
                                    <div>
                                      <b>Start: </b>
                                      <StyledTableData>
                                        {startAt}
                                      </StyledTableData>
                                    </div>
                                    <StyledHr />
                                    <div>
                                      <b>Close: </b>
                                      <StyledTableData>
                                        {eventRegistrationClosesAt || 'n/a'}
                                      </StyledTableData>
                                    </div>
                                    <StyledHr />
                                    <div>
                                      <b>Attendees: </b>
                                      <StyledTableData>
                                        {numAttendees}
                                      </StyledTableData>
                                    </div>
                                  </TableCell>
                                </>
                              )}
                            </StyledTableRow>
                          ),
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              15,
                              25,
                              { label: 'All', value: -1 },
                            ]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <center>
                  <em className={'faded'}>
                    <LinearProgress
                      style={{
                        fontSize: 'small',
                        backgroundColor: '#f57c00',
                      }}
                    />
                    <br />
                    Finding some upcoming online tournaments...
                  </em>
                </center>
              )}
            </CardContent>
          </StyledCard>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Body>
      <Toast
        message={
          <div style={{ cursor: 'pointer' }}>
            {message.type !== 'error' && <ToastSpinner />}{' '}
            <span
              style={{ verticalAlign: 'middle' }}
              dangerouslySetInnerHTML={{ __html: message.text }}
            />
          </div>
        }
        open={loading}
        autoHideDuration={15000}
        onClick={() => setLoading(false)}
      />
      <Footer />
    </Fragment>
  )
}
