import React, { useContext } from 'react'
import { Context } from '../route/Overlay'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import bgPlayerContainerGif from '../assets/img/bgPlayer.gif'
import loadingGif from '../assets/img/loading.gif'
import eventImage from '../assets/img/event.png'

export default function Metro() {
  const { loading, player1, player2, event, params } = useContext(Context)

  return (
    <center
      style={{
        width: '100%',
        paddingTop: '5px',
        backgroundColor: 'transparent !important',
      }}
    >
      {event.showImage && (
        <>
          {loading || params.dance ? (
            <EventImage src={loadingGif} />
          ) : (
            <EventImage src={event.image || eventImage} />
          )}
        </>
      )}

      <Grid
        container
        style={{
          backgroundColor: 'transparent',
          minWidth: '1080px',
          maxWidth: '1080px',
        }}
        alignItems={'center'}
      >
        {(event.showName || event.showRoundText) && (
          <>
            <Grid item xs={2} />
            <PlayerGridContainer item xs={8}>
              <PlayerGrid container alignItems="center" orientation={'left'}>
                {event.showName && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                      color: '#45b3cd',
                      fontWeight: 'bold',
                    }}
                  >
                    {event.name}
                  </Grid>
                )}
                {event.showRoundText && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                      color: '#45b3cd',
                      fontStyle: 'italic',
                    }}
                  >
                    {event.fullRoundText}
                  </Grid>
                )}
              </PlayerGrid>
            </PlayerGridContainer>
            <Grid item xs={2} />
          </>
        )}

        <Grid item xs={2} />
        <PlayerGridContainer item xs={4}>
          <PlayerGrid container alignItems="center" orientation={'left'}>
            <Grid item xs={2} style={{ textAlign: 'left' }}>
              {loading || params.dance ? (
                <Image
                  src={loadingGif}
                  style={{ transform: 'scaleX(-1)' }}
                  width={35}
                  height={35}
                />
              ) : (
                <Image
                  src={
                    player1.image ||
                    'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                  }
                  width={35}
                  height={35}
                />
              )}
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player1.tag && <TextAlt>{player1.tag} | </TextAlt>}
              <Text>{player1.player}</Text>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                textAlign: 'center',
                backgroundColor: 'white',
                borderBottom: '4px solid #eb3f53',
              }}
            >
              <TextScore>{player1.score || 0}</TextScore>
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>
        <PlayerGridContainer item xs={4}>
          <PlayerGrid container alignItems="center" orientation={'right'}>
            <Grid
              item
              xs={2}
              style={{
                textAlign: 'center',
                backgroundColor: 'white',
                borderBottom: '4px solid #3087d7',
              }}
            >
              <TextScore>{player2.score || 0}</TextScore>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player2.tag && <TextAlt>{player2.tag} | </TextAlt>}
              <Text>{player2.player}</Text>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              {loading || params.dance ? (
                <Image src={loadingGif} width={35} height={35} />
              ) : (
                <Image
                  src={
                    player2.image ||
                    'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                  }
                  width={35}
                  height={35}
                />
              )}
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>
        <Grid item xs={2} />

        {/* {event.showName && <Grid item xs={12} style={{textAlign: 'center'}}>
        <EventName>{event.name}</EventName>
      </Grid>} */}
        {/* {event.showRoundText && <Grid item xs={12} style={{textAlign: 'center'}}>
        <FullRoundText>{event.fullRoundText}</FullRoundText>
      </Grid>} */}
      </Grid>
    </center>
  )
}

const Body = styled.div`
  width: 100%;
  padding-top: 5px;
  background-color: transparent !important;
`
const Text = styled.span`
  user-select: none;
  color: white;
  text-shadow: 2px 2px rgba(68, 68, 68, 1);
  font-size: x-large;
  vertical-align: middle;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const TextScore = styled.span`
  user-select: none;
  color: black;
  font-size: x-large;
  vertical-align: middle;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const TextAlt = styled.span`
  user-select: none;
  color: #888888;
  text-shadow: 2px 2px rgba(68, 68, 68, 1);
  font-size: large;
  vertical-align: middle;
  font-family: Comic Sans MS, Comic Sans, cursive;
`

const EventName = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px rgba(68, 68, 68, 1);
  font-size: large;
  font-weight: bold;
  vertical-align: middle;
  padding: 2px;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const FullRoundText = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px rgba(68, 68, 68, 1);
  font-size: medium;
  font-weight: bold;
  vertical-align: middle;
  padding: 2px;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const Image = styled.img`
  padding: 1px;
  display: inline-block;
  vertical-align: middle;
  max-width: 35px;
  height: 35px;
  width: auto;
`

const EventImage = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 4px;
`

const EventImageBackground = styled(Grid)`
  display: inline-block;
  padding: 2px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  ${'' /* background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif'); */}
`
const PlayerGridContainer = styled(Grid)`
  padding: 2px;
  ${'' /* background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif'); */}
`

const PlayerGrid = styled(Grid)`
  background-color: #354364f8;
`
