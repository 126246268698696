import React, { useContext } from 'react'
import { Context } from '../route/Overlay'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import bgPlayerContainerGif from '../assets/img/bgPlayer.gif'
import loadingGif from '../assets/img/loading.gif'
import eventImage from '../assets/img/event.png'

export default function Light() {
  const { loading, player1, player2, event, params } = useContext(Context)

  return (
    <center
      style={{
        width: '100%',
        paddingTop: '5px',
        backgroundColor: 'transparent !important',
      }}
    >
      <Grid
        container
        style={{
          backgroundColor: 'transparent',
          minWidth: '1080px',
          maxWidth: '1080px',
        }}
        alignItems={'center'}
      >
        <Grid item xs={1} />
        <PlayerGridContainer item xs={4}>
          <PlayerGrid container alignItems="center" orientation={'left'}>
            <Grid item xs={2}>
              <PlayerImageBackground>
                {loading || params.dance ? (
                  <Image
                    src={loadingGif}
                    style={{ transform: 'scaleX(-1)' }}
                    width={50}
                    height={50}
                  />
                ) : (
                  <Image
                    src={
                      player1.image ||
                      'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                    }
                    width={50}
                    height={50}
                  />
                )}
              </PlayerImageBackground>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player1.tag && <TextAlt>{player1.tag} | </TextAlt>}
              <Text>{player1.player}</Text>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'center', borderLeft: '1px solid black' }}
            >
              <Text>{player1.score || 0}</Text>
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {params.type === 'tournament' && event.showImage && (
            <EventImageBackground>
              {loading || params.dance ? (
                <EventImage src={loadingGif} />
              ) : (
                <EventImage src={event.image || eventImage} />
              )}
            </EventImageBackground>
          )}
        </Grid>
        <PlayerGridContainer item xs={4}>
          <PlayerGrid container alignItems="center" orientation={'right'}>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'center', borderRight: '1px solid black' }}
            >
              <Text>{player2.score || 0}</Text>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player2.tag && <TextAlt>{player2.tag} | </TextAlt>}
              <Text>{player2.player}</Text>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <PlayerImageBackground>
                {loading || params.dance ? (
                  <Image src={loadingGif} width={50} height={50} />
                ) : (
                  <Image
                    src={
                      player2.image ||
                      'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                    }
                    width={50}
                    height={50}
                  />
                )}
              </PlayerImageBackground>
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>
        <Grid item xs={1} />

        {event.showName && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <EventName>{event.name}</EventName>
          </Grid>
        )}
        {event.showRoundText && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <FullRoundText>{event.fullRoundText}</FullRoundText>
          </Grid>
        )}
      </Grid>
    </center>
  )
}

const Body = styled.div`
  width: auto;
  padding-top: 5px;
  padding-left: 350px;
  padding-right: 350px;
`
const Text = styled.span`
  text-shadow: 1px 1px #a3a3a3ff;
  color: black;
  font-size: xx-large;
  vertical-align: middle;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const TextAlt = styled.span`
  user-select: none;
  color: #888888;
  text-shadow: 2px 2px rgba(68, 68, 68, 1);
  font-size: x-large;
  vertical-align: middle;
  font-family: Comic Sans MS, Comic Sans, cursive;
`

const EventName = styled.span`
  text-shadow: 1px 1px #a3a3a3ff;
  color: white;
  font-size: large;
  font-weight: bold;
  vertical-align: middle;
  border-radius: 2px;
  padding: 2px;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const FullRoundText = styled.span`
  text-shadow: 1px 1px #a3a3a3ff;
  color: white;
  font-size: medium;
  font-weight: bold;
  vertical-align: middle;
  border-radius: 2px;
  padding: 2px;
  font-family: Comic Sans MS, Comic Sans, cursive;
`
const Image = styled.img`
  display: inline-block;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  width: auto;
  height: auto;
  vertical-align: middle;
  border-radius: 10%;
`

const EventImage = styled.img`
  width: 75px;
  height: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 860px;
  top: 5px;
  border-radius: 4px;
  z-index: -1;
`
const EventImageBackground = styled(Grid)`
  display: inline-block;
  padding: 2px;
  width: 75px;
  height: 75px;
  border-radius: 4px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`
const PlayerGridContainer = styled(Grid)`
  border-radius: 4px;
  padding: 2px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`
const PlayerImageBackground = styled(Grid)`
  display: inline-block;
  padding: 1px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`

const PlayerGrid = styled(Grid)`
  border-radius: 4px;
  background-color: #000000;
  padding: 2px;
  background-image: ${({ orientation }) =>
    orientation === 'left'
      ? 'linear-gradient(90deg, rgba(119,119,119,1) 0%, rgba(255,255,255,1) 18%, rgba(255,255,255,1) 80%, rgba(119,119,119,1) 100%);'
      : 'linear-gradient(270deg, rgba(119,119,119,1) 0%, rgba(255,255,255,1) 18%, rgba(255,255,255,1) 80%, rgba(119,119,119,1) 100%);'};
`
