import {gql} from 'apollo-boost';

const SSBU_GAME_ID = 1386;
/* TODO - SelectionValue is not correct. paginatedSets ordering is not correct */

export const UPCOMING_SSBU_TOURNAMENTS = gql`
    query UpcomingTournaments($afterDate: Timestamp) {
        tournaments(query:{filter:{publiclySearchable: true, hasOnlineEvents:true, regOpen:true,videogameIds: 1386,afterDate: $afterDate, publiclySearchable: true }, sortBy: "startAt ASC", perPage:250}){
            nodes{
                links{
                    facebook
                    discord
                }
                images{
                    url
                }
                events{
                    type
                    images{
                        url
                    }
                }
                id
                numAttendees
                url
                isOnline
                tournamentType
                isRegistrationOpen
                startAt
                endAt
                eventRegistrationClosesAt
                name
            }
        }
    }
`;

export const GET_FULL_EVENT = gql`
    query GetEntrantFromTournamentSlug($slug: String, $eventId: [ID], $player: String) {
        tournament(slug: $slug) {
            name
            images(type:"profile"){
                url
            }
            events {
                id
                numEntrants
                entrants(query: { filter: { name: $player } }) {
                    nodes {
                        standing {
                            id
                            placement
                            player {
                                gamerTag
                                sets(filters:{eventIds: $eventId}, perPage: 1) {
                                    # Only need the latest set
                                    nodes {
                                        id
                                        state
                                        displayScore
                                        fullRoundText
                                        startedAt
                                        slots {
                                            entrant {
                                                id
                                                name
                                                    participants {
                                                    id
                                                    player {
                                                        id
                                                        gamerTag
                                                        user {
                                                            images(type: "profile"){
                                                                type
                                                                url
                                                            }
                                                            name
                                                        }
                                                    }
                                                }
                                                paginatedSets(sortType: RECENT, perPage: 1){
                                                    nodes{
                                                        game1: game(orderNum: 1){ 
                                                            selections{
                                                                entrant{
                                                                    id
                                                                }
                                                                selectionType
                                                                selectionValue
                                                            }
                                                        }
                                                        game2: game(orderNum: 2){ 
                                                            selections{
                                                                entrant{
                                                                    id
                                                                }
                                                                selectionType
                                                                selectionValue
                                                            }
                                                        },
                                                        game3: game(orderNum: 3){ 
                                                            selections{
                                                                entrant{
                                                                    id
                                                                }
                                                                selectionType
                                                                selectionValue
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            standing {
                                                stats {
                                                    score {
                                                        value,
                                                        displayValue
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }`;


export const GET_EVENT_ID = gql`
    query GET_EVENT_ID($slug: String) {
        tournament(slug: $slug){
            name
            images(type:"profile"){
                url
            }
            events{
                id
            }
        }
    }`;


export const SEED_GET_ENTRANT_DATA = gql`
    query GetEntrantsFromTournamentSlug($slug: String) {
        tournament(slug: $slug){
            events(limit: 1){
                entrants(query: { page: 1, perPage: 500 }){
                    pageInfo{
                        total
                        perPage
                        page
                    }
                    nodes{
                        name
                        participants{
                            player{
                                id
                                gamerTag
                            }
                            user{
                                id
                            }
                        }
                    }
                }

            }
        }
    }
`;

export const SEED_GET_USER_WIN_RATE = gql`
    query MatchQuery ($user_id: ID, $user_name:  String){
        user(id: $user_id){
            tournaments(query:{filter:{past:true}}){
                nodes{
                    name
                    events(filter:{videogameId:1386, type: 1}){
                        numEntrants
                        standings(query:{page:1, perPage: 100, filter:{search:{searchString: $user_name}}}){
                            nodes{
                                placement
                            }
                        }
                    }
                }
            }
        }
    }`;
export const SEED_GET_USER_WIN_RATE2 = gql`
    query MatchQuery ($player_id: ID!){
        player(id: $player_id){
            sets(page:1, perPage:100){
                nodes{
                    displayScore
                }
            }
        }
    }`;

export const SEED_GET_SEED_DATA = gql`
    query GetSeedData($slug: String) {
        tournament(slug: $slug){
            name
            events{
                phases{
                    id
                    seeds(query: { page: 1, perPage: 500 }) {
                        nodes {
                            id
                            seedNum
                            entrant {
                                id
                                participants {
                                    gamerTag
                                    user{
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SEED_MUTATE_PHASE_SEEDING = gql`
    mutation UpdatePhaseSeeding ($phaseId: ID!, $seedMapping: [UpdatePhaseSeedInfo]!) {
        updatePhaseSeeding (phaseId: $phaseId, seedMapping: $seedMapping) {
            id
        }
    }
`;

// export const GET_ENTRANT_SET_DATA = gql`
//     query GetEntrantFromTournamentSlug($slug: String, $eventId: [ID], $player: String) {
//         tournament(slug: $slug){
//             name
//             events{
//                 id
//                 numEntrants
//                 entrants(query:{ filter:{name: $player}}){
//                     nodes{
//                         standing{
//                             id
//                             placement
//                             player{
//                                 gamerTag
//                                 sets(filters:{eventIds: $eventId}, perPage: 1){ # Only need the latest set
//                                     nodes{
//                                         state
//                                         displayScore
//                                         fullRoundText
//                                         startedAt
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }`;
// export const GET_ENTRANT_PHOTO = gql`
//     query TournamentQuery($slug: String,$player: String) {
//         tournament(slug: $slug){
//             name
//             participants(
//                 query:{
//                     perPage:1,
//                     filter:{
//                         search:{
//                             searchString:$player,
//                             fieldsToSearch:"gamerTag"
//                         }
//                     }
//                 }
//             ){
//                 nodes{
//                     gamerTag,
//                     user{
//                         images(type:"profile"){
//                             url
//                         }
//                     }
//                 }
//             }
//         }
//     }`;
// export const GET_TOURNEY = gql`
//     query TournamentQuery($slug: String) {
//         tournament(slug: $slug){
//             name,
//             city,
//             events{
//                 videogame {
//                     name
//                 }
//             },
//             participants(query:{}){
//                 pageInfo{
//                     total
//                 }
//             }
//         }
//     }
// `;
// export const GET_ALL_LAST_SET_INFO = gql`
//     query GET_LAST_SET_INFO($slug: String) {
//         tournament(slug: $slug){
//             name
//             events{
//                 id
//                 numEntrants
//                 entrants{
//                     nodes{
//                         standing{
//                             id
//                             placement
//                             player{
//                                 id
//                                 gamerTag
//                                 sets(page:1, perPage:1){  # Get the latest set
//                                     nodes{
//                                         state
//                                         displayScore
//                                         fullRoundText
//                                         startedAt
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }`;

// export const GET_SETS = gql`
//     query TournamentQuery($slug: String) {
//         tournament(slug: $slug){
//             name,
//             city,
//             events{
//                 videogame {
//                     name
//                 }
//             },
//             participants(query:{}){
//                 pageInfo{
//                     total
//                 }
//             }
//         }
//     }
// `;

// export const GET_RESULTS = gql`
//     query TournamentQuery($slug: String,$player: String) {
//         tournament(slug: $slug){
//             name
//             participants(
//                 query:{
//                     perPage:1,
//                     filter:{
//                         search:{
//                             searchString:$player,
//                             fieldsToSearch:"gamerTag"
//                         }
//                     }
//                 }
//             ){
//                 nodes{
//                     gamerTag,
//                     events{
//                         name,
//                         numEntrants,
//                         state,
//                         standings(
//                             query:{
//                                 filter:{
//                                     search:{
//                                         searchString:$player,
//                                         fieldsToSearch:"gamerTag"
//                                     }
//                                 }
//                             }
//                         ){
//                             nodes{
//                                 standing
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }`;
