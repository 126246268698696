import React, { useContext } from 'react'
import { Context } from '../route/Overlay'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import bgPlayerContainerGif from '../assets/img/bgPlayer.gif'
import loadingGif from '../assets/img/loading.gif'
import eventImage from '../assets/img/event.png'

export default function YouToogle() {
  const { loading, player1, player2, event, params } = useContext(Context)
  return (
    <center
      style={{
        width: '100%',
        paddingTop: '5px',
        backgroundColor: 'transparent !important',
      }}
    >
      <Grid
        container
        style={{
          backgroundColor: 'transparent',
          minWidth: '1080px',
          maxWidth: '1080px',
        }}
        alignItems={'center'}
      >
        <PlayerGridContainer item xs={5}>
          <PlayerGrid
            container
            alignItems="center"
            orientation={'left'}
            style={{
              borderRadius: '25px',
              mixBlendMode: 'screen',
              backgroundImage: 'url("https://i.ibb.co/xfGStBF/YTR.gif")',
              backgroundPosition: '94%',
              backgroundSize: '600px',
            }}
          >
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <PlayerImageBackground>
                {loading || params.dance ? (
                  <Image
                    src={loadingGif}
                    style={{ transform: 'scaleX(-1)' }}
                    width={50}
                    height={50}
                  />
                ) : (
                  <Image
                    src={
                      player1.image ||
                      'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                    }
                    width={50}
                    height={50}
                  />
                )}
              </PlayerImageBackground>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player1.tag && <TextAlt>{player1.tag} | </TextAlt>}
              <Text>{player1.player}</Text>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ textAlign: 'center', borderLeft: '1px solid white' }}
            >
              <TextScore>{player1.score || 0}</TextScore>
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>

        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {params.type === 'tournament' && event.showImage && (
            <EventImageBackground>
              {loading || params.dance ? (
                <EventImage src={loadingGif} />
              ) : (
                <EventImage src={event.image || eventImage} />
              )}
            </EventImageBackground>
          )}
        </Grid>

        <PlayerGridContainer item xs={5}>
          <PlayerGrid
            container
            alignItems="center"
            orientation={'right'}
            style={{
              borderRadius: '25px',
              mixBlendMode: 'screen',
              backgroundImage: 'url("https://i.ibb.co/QFp50hd/YTL.gif")',
              backgroundPosition: '6% 40%',
              backgroundSize: '600px',
            }}
          >
            <Grid
              item
              xs={2}
              style={{ textAlign: 'center', borderRight: '1px solid white' }}
            >
              <TextScore>{player2.score || 0}</TextScore>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'center' }}>
              {player2.tag && <TextAlt>{player2.tag} | </TextAlt>}
              <Text>{player2.player}</Text>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'left' }}>
              <PlayerImageBackground>
                {loading || params.dance ? (
                  <Image src={loadingGif} width={50} height={50} />
                ) : (
                  <Image
                    src={
                      player2.image ||
                      'https://images.smash.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png'
                    }
                    width={50}
                    height={50}
                  />
                )}
              </PlayerImageBackground>
            </Grid>
          </PlayerGrid>
        </PlayerGridContainer>

        {event.showName && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <EventName>{event.name}</EventName>
          </Grid>
        )}
        {event.showRoundText && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <FullRoundText>{event.fullRoundText}</FullRoundText>
          </Grid>
        )}
      </Grid>
    </center>
  )
}

const Body = styled.div`
  width: 100%;
  padding-top: 5px;
  background-color: transparent !important;
`
const Text = styled.span`
  user-select: none;
  color: white;
  text-shadow: 4px 4px black;
  font-size: xx-large;
  vertical-align: middle;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const TextScore = styled.span`
  user-select: none;
  color: black;
  text-shadow: 4px 4px #ccc;
  font-size: xx-large;
  vertical-align: middle;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const TextAlt = styled.span`
  user-select: none;
  color: #bbb;
  text-shadow: 2px 2px black;
  font-size: x-large;
  vertical-align: middle;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`

const EventName = styled.span`
  user-select: none;
  color: white;
  text-shadow: 2px 2px rgba(68, 68, 68, 1);
  font-size: large;
  font-weight: bold;
  vertical-align: middle;
  border-radius: 2px;
  padding: 2px;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const FullRoundText = styled.span`
  user-select: none;
  color: white;
  text-shadow: 1px 1px rgba(68, 68, 68, 1);
  font-size: medium;
  font-weight: bold;
  vertical-align: middle;
  border-radius: 2px;
  padding: 2px;
  font-family: fantasy, Comic Sans MS, Comic Sans, cursive;
`
const Image = styled.img`
  display: inline-block;
  max-width: 30px;
  max-height: 30px;
  min-width: 30px;
  min-height: 30px;
  width: auto;
  height: auto;
  vertical-align: middle;
  border-radius: 15px;
`

const EventImage = styled.img`
  width: 75px;
  height: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 860px;
  top: 5px;
  border-radius: 4px;
  z-index: -1;
`
const EventImageBackground = styled(Grid)`
  display: inline-block;
  padding: 2px;
  width: 75px;
  height: 75px;
  border-radius: 4px;
  background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif');
`
const PlayerGridContainer = styled(Grid)`
  border-radius: 4px;
  padding: 2px;
  ${'' /* background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif'); */}
`
const PlayerImageBackground = styled(Grid)`
  display: inline-block;
  padding: 1px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  ${'' /* background-image: url('https://i.ibb.co/PG6wngg/bgPlayer.gif'); */}
`

const PlayerGrid = styled(Grid)`
  padding: 2px;
`
