import {Snackbar} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import React from 'react';

export default function Toast({message, className, vertical = 'top', horizontal = 'left', ...props}) {

    return <Snackbar
        anchorOrigin={{vertical, horizontal}}
        key={vertical + horizontal}
        message={message}
        TransitionComponent={Slide}
        className={'toast-spinner'}
        style={{width: '98%', left: '1%', right: '1%', top: '5%'}}
        {...props}
    />;

}
