import React, { createContext, useEffect, useState, Fragment } from 'react'
import Dark from '../themes/Dark'
import useSmashGG from '../hooks/useSmashGG'
import Light from '../themes/Light'
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import { orange } from '@material-ui/core/colors'
import CoinBox from '../themes/CoinBox'
import Metro from '../themes/Metro'
import VG from '../themes/VG'
import io from 'socket.io-client'
import YouToogle from '../themes/YouToogle'

export const Context = createContext({})
const theme = createTheme({
  status: {
    danger: orange[500],
  },
})

function Overlay() {
  const socket = io.connect(
    window.location.hostname === 'localhost'
      ? `http://localhost:4000`
      // : `http://smashtube.ca:4000`,
      : `https://smashtube-ws.herokuapp.com/`,
  )
  const [connected, setConnected] = useState(false)
  socket.on('connect', () => {
    setConnected(true)
  })

  const [state, setState] = useState({})

  useEffect(() => {
    if (connected) {
      const urlParams = new URLSearchParams(
        window.location.href?.split('?')?.[1] || '',
      )
      let hashId = urlParams.get('key')

      if (hashId) {
        // socket.emit('#log', '<Overlay/>');
        socket.emit('#register', { hashId })

        socket.on('#overlay', ({ apiKey, url }) => {
          setState({ apiKey, url })
        })
      }

      return () => socket.disconnect()
    }
  }, [connected])

  let { player1, player2, params, event, loading } = useSmashGG(state)

  if (params.swapPlayers) {
    let tmpPlayer1 = player1
    player1 = player2
    player2 = tmpPlayer1
  }

  const Theme = ({ theme }) => {
    switch (theme) {
      case 'coinbox':
        return <CoinBox />
      case 'metro':
        return <Metro />
      case 'dark':
        return <Dark />
      case 'light':
        return <Light />
      case 'vg':
        return <VG />
      case 'youtoogle':
        return <YouToogle />
      default:
        return <Metro />
    }
  }

  return (
    <Fragment>
      <Context.Provider value={{ player1, player2, params, event, loading }}>
        <ThemeProvider theme={theme}>
          <Theme theme={params.theme} />
        </ThemeProvider>
      </Context.Provider>
    </Fragment>
  )
}

export default Overlay
